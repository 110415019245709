import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import loadable from '@loadable/component'
import Layout from '../../layouts/Layout/Layout'
import './CareerBrokerAccreditation.scss'
import NAVIGATIONS from '../../data/career-tabs.json'

import LandingPageLayout from '../../layouts/LandingPageLayout/LandingPageLayout'
import Seo from '../../components/Seo/Seo'
import {
  addFormValidation,
  setIsIframe,
} from '../../components/Validators/InputValidations'
import CareerBanner from '../../assets/images/careers-page-banner.png'
import { removeChatbot, showChatbot } from '../../services/snapengage'

const PopupModal = loadable(() =>
  import('../../components/PopupModal/PopupModal'),
)

const ThirdPartyForm = loadable(() =>
  import('../../components/ThirdPartyForm/ThirdPartyForm'),
)

type MainPropTypes = {
  pageContext: {
    activeLabel: string
  }
}

const CareerBrokerAccreditation = ({
  pageContext: { activeLabel },
}: MainPropTypes): ReactElement => {
  const [isSuccessShown, setIsSuccessShown] = useState<boolean>(false)
  const [displayForm, setDisplayForm] = useState(true)

  useEffect(() => {
    removeChatbot()

    return (): void => {
      showChatbot()
    }
  }, [])

  useEffect(() => {
    if (!displayForm) {
      setDisplayForm(true)
    }
  }, [displayForm])

  const handleSubmit = useCallback(() => {
    setIsSuccessShown(true)
  }, [])

  const handleSuccessClose = useCallback(() => {
    setIsSuccessShown(false)
    setDisplayForm(false)
  }, [])

  const handleFormReady = useCallback(() => {
    setIsIframe(true)
    addFormValidation('broker-accreditation-form', [
      {
        elementId: 'firstname-696c34cd-ab36-4ca8-8a70-a6b731253b1b',
        validationType: 'alphaOnly',
      },
      {
        elementId: 'lastname-696c34cd-ab36-4ca8-8a70-a6b731253b1b',
        validationType: 'alphaOnly',
      },
      {
        elementId: 'phone-696c34cd-ab36-4ca8-8a70-a6b731253b1b',
        validationType: 'numericOnly',
      },
      {
        elementId: 'broker_realty_company-696c34cd-ab36-4ca8-8a70-a6b731253b1b',
        validationType: 'alphaNumericWithSpecialCharacters',
      },
      {
        elementId: 'message-696c34cd-ab36-4ca8-8a70-a6b731253b1b',
        validationType: 'alphaNumericWithSpecialCharacters',
      },
      {
        elementId: 'mobilephone-696c34cd-ab36-4ca8-8a70-a6b731253b1b',
        validationType: 'numericOnly',
      },
    ])
  }, [])

  return (
    <Layout>
      <Seo
        jsonData={{
          url: `${process.env.GATSBY_SITE_URL}/careers/broker-accreditation/`,
          keywords:
            'properties, condo, philippines, house and lot, avidaland, avida, apartment, careers, avidaland careers, broker, broker accreditation',
        }}
        title="Career Opportunities | Broker Accreditation"
      />
      <div className="career-list">
        <LandingPageLayout
          title="Career Opportunities"
          tabNavigation={NAVIGATIONS}
          activeLabel={activeLabel}
          banner={CareerBanner}
          body="We are on the lookout for smart and talented individuals with a relentless passion to develop thriving sustainable communities across the Philippines. Explore our Career Opportunities and be part of Avida."
        >
          <div className="career-broker">
            <h2 className="career-broker-header">{activeLabel}.</h2>
            <p className="career-broker-description">Registration Form</p>
            <p className="career-broker-body">
              The Broker Operations group accredits licensed real estate brokers
              to market and sell Avida properties.
            </p>
            <p className="career-broker-body">
              Please fill-in your details below. A Broker Relations Associate
              will contact you for your preferred schedule of accreditation.
            </p>
            <p className="career-broker-contact">
              You may also call <span>(+63917) 8105147</span>
            </p>
          </div>
          {!!displayForm && (
            <ThirdPartyForm
              id="broker-accreditation-form"
              portalId="4090950"
              formId="696c34cd-ab36-4ca8-8a70-a6b731253b1b"
              onFormSubmit={handleSubmit}
              onFormReady={handleFormReady}
            />
          )}
        </LandingPageLayout>
      </div>
      <PopupModal
        isShowing={isSuccessShown}
        onClose={handleSuccessClose}
        header="Thank you for your inquiry"
        body="Thank you for submitting your Broker Accreditation registration form. A Broker Relations Officer will get in touch with you within 48 hours."
        contactList={[
          'Sales Hotline: (+632) 848-5200',
          'Office Trunk Line: (+632) 988-2111',
        ]}
      />
    </Layout>
  )
}

export default CareerBrokerAccreditation
